import React from 'react'
import { Icon } from '@iconify/react'
import caretUpFilled from '@iconify/icons-ant-design/caret-up-filled'

const ScrollTop = (props) => (
  <a className='ScrollTop' href='#top'>
    <Icon className='ScrollTop__icon' icon={caretUpFilled} />
    <span className='ScrollTop__text'>Scroll to top</span>
  </a>
)

export default ScrollTop