import React from 'react'
import Layout from '../../components/layout'
import PortfolioHeader from '../../components/portfolio-header'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Timeline from '../../components/timeline'
import Recommendation from '../../components/recommendation'
import ScrollTop from '../../components/scroll-top'

const RINexusPage = (props) => (
  <Layout>
    <Timeline />
    <article className='Portfolio'>
      <section className='Portfolio__intro'>
        <PortfolioHeader name='RI Nexus' parttime subname='ThoughtCap' position='Drupal Developer' startDate='2007-07' endDate='2008-09-30' logo={props.data.companyLogo.childImageSharp.gatsbyImageData} />
        <p className='CompanyIntro'>
          RI Nexus was a public-private initiative of the Rhode Island Economic Development Corporation. It was created to act as a hub for connecting Rhode Island’s info-tech & digital media community.
        </p>
      </section>
      <section className='Portfolio__role'>
        <h2 className='SectionTitle'>My role</h2>
        <p>
          RI Nexus was my first foray into the professional world of web development. Introduced through a referral by one of my university professors, I soon began working part-time with a handful of innovators. Working primarily with the program director and one senior developer, I tackled designing & developing various aspects of rinexus.com.
        </p>
        <h2 className='SectionTitle'>Details</h2>
        <p>
          RI Nexus was built on Drupal, an open-source CMS. Many of my tasks involved improving the user interface for both end-users and content administrators. As well as developing new sections and features, such as the marketplace that enabled local companies to post openings for jobs, gigs, and internships. These tasks involved HTML, CSS, PHP, mySQL, jQuery, and Drupal module development.
        </p>
      </section>
      <section className='Portfolio__recommendations'>
        <h2 className='SectionTitle'>Recommendations</h2>
        <Recommendation name='Jack Templin' date='2008' position='Managing Partner at Thoughtcap'>
          I’ve had the pleasure of working with Collin for several months now. He is smart, hard-working, and professional. I’ve been most impressed by his ability to learn new things quickly and thoroughly. He is also a good guy. I would hire him again, and highly recommend him.
        </Recommendation>
      </section>
      <section className='Portfolio__examples'>
        <h2 className='SectionTitle SectionTitle__gallery'>Work examples</h2>
        <div className='Gallery'>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.homeScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.calendarScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.directoryScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
          <div className='Gallery__item'>
            <GatsbyImage
              className='GalleryImg'
              image={props.data.marketplaceScreenshot.childImageSharp.gatsbyImageData}
              alt=''
            />
          </div>
        </div>
      </section>
      <ScrollTop />
    </article>
    <Timeline />
  </Layout>
)

export default RINexusPage

export const pageQuery = graphql`
  query {
    companyLogo: file(relativePath: { eq: "logos/rinexus.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 300
        )
      }
    },
    homeScreenshot: file(relativePath: { eq: "screenshots/rinexus/home.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    calendarScreenshot: file(relativePath: { eq: "screenshots/rinexus/calendar.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    directoryScreenshot: file(relativePath: { eq: "screenshots/rinexus/directory.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    marketplaceScreenshot: file(relativePath: { eq: "screenshots/rinexus/marketplace.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    }
  }
`
