import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const Recommendation = (props) => (
  <div className='RecommendationMade'>
    <div className='RecommendationMade__from'>
      <div className='RecommendationMade__name'>
        {props.name}
      </div>
      <div className='RecommendationMade__position'>
        {props.position}
      </div>
      <div className='RecommendationMade__date'>
        {props.date}
      </div>
    </div>
    <div className='RecommendationMade__quote'>
      <p>
        “
        {props.children}
        ”
        <span className='nowrap'> — via <OutboundLink href='https://www.linkedin.com/in/collinw' target='_blank' rel='noopener noreferrer'>LinkedIn</OutboundLink></span>
      </p>
    </div>
  </div>
)

export default Recommendation
